import React from 'react';
import { compose } from 'recompose';
import Layout from '../utils/layout';
import { graphql } from 'gatsby';
import {
  withAuthorization,
  withEmailVerification,
} from '../utils/Session';
import LessonsPlan from '../components/scenes/LessonsPlan/LessonsPlan';
import { AuthUserContext } from '../utils/Session';

const condition = (authUser) => !!authUser;
const LessonsPlanPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(LessonsPlan);

export default () => {
  return (
    <Layout>
      <AuthUserContext.Consumer>
        {(authUser) => <LessonsPlanPage authUser={authUser} />}
      </AuthUserContext.Consumer>
    </Layout>
  );
};

export const query = graphql`
  query LessonsPlanSeo {
    site {
      siteMetadata {
        home {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: url
          defaultImage: image
          twitterUsername
        }
      }
    }
  }
`;
