import React, { Component } from 'react';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import { FREE_LESSONS } from '../../../constants/routes';
import lessonsPlanData from './../../../data/lessonsPlan';

class StripeCard extends Component {
  render() {
    const { index, lessons, onClick } = this.props;
    const progressStyle =
      'calc(' + (parseInt(index) / 48) * 100 + '% - 20px)';
      const paymentClass = index <= lessons.payed ? 'payed' : '';
      const availabilityClass = index <= lessons.available ? 'available' : '';
    return (
      <div className="stripe-card text-center" >
        <div className={`stripe-card-inner ${paymentClass} ${availabilityClass}`} onClick={onClick}><span className="number">{index}</span></div>
        <div className="progress" style={{ width: progressStyle }} />
      </div>
    );
  }
}

export default class LessonsPlan extends React.Component {
  state = {
    selectedLesson: this.props.authUser.study && this.props.authUser.study.availableLesson || 1,
    mode: '', // beshkechytun-mode
  };

  slide = (wheelEvent) => {
    wheelEvent.wheelDelta > 0
      ? this.slider.slickPrev()
      : this.slider.slickNext();
    wheelEvent.preventDefault();
  };

  componentDidMount() {
    document
      .getElementById('slider')
      .addEventListener('wheel', this.slide);
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.slide);
  }

  toggleMode = () => {
    this.setState({
      mode:
        this.state.mode === 'lesson-info-mode'
          ? 'beshkechytun-mode'
          : 'lesson-info-mode',
    });
  };

  render() {
    const { authUser } = this.props;
    const lessons = {payed: authUser.payments && authUser.payments.payedLesson || 5, available: authUser.study && authUser.study.availableLesson || 1};
    let settings = {
      dots: false,
      arrows: true,
      focusOnSelect: false,
      infinite: false,
      speed: 350,
      slidesToShow: 6,
      slidesToScroll: 6,
      adaptiveHeight: true,
    };
    let onLessonSelect = (lesson) =>
      this.setState({ selectedLesson: lesson });
    return (
      <div className="main plan-urokiv">
        <div className="site-content">
          <div className="container-fluid">
            <div
              className={
                `row selected-lesson d-flex ${this.state.selectedLesson <= lessons.payed ? 'payed' : ''} ${this.state.selectedLesson <= lessons.available ? 'available' : ''} ${this.state.mode}`
              }
              onClick={this.toggleMode}
            >
              <div className="d-flex flex-row justify-content-center">
                <div className="col-xs-3 col-sm-offset-1 number text-center d-flex justify-content-center align-items-center">
                  <span>{this.state.selectedLesson}</span>
                </div>
                  <div className="col-xs-5 d-flex flex-column justify-content-center align-items-flex-start">
                    <div>
                      <strong>
                        {
                          lessonsPlanData[this.state.selectedLesson]
                            .header
                        }
                      </strong>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            lessonsPlanData[this.state.selectedLesson]
                              .content,
                        }}
                      />
                    </div>
                    <Link
                      to={`${FREE_LESSONS}/${this.state.selectedLesson}/1`}
                      className={`go-to-lesson-button ${this.state.selectedLesson <= lessons.available ? 'available' : ''}`}
                    >
                      До уроку!
                    </Link>
                  </div>
              </div>
              {/*
              <div className="beshkechytun-field d-flex">
                Бешкечитун
                <Image
                  className="beshkechytun"
                  alt="Бешкечитун"
                  title="Бешкечитун"
                  filename="beshkechytun-01.svg"
                />
              </div>
              */}
            </div>
            <div className="row lessons-stripe">
              <div className="col-xs-12">
                <div id="slider">
                  <Slider
                    {...settings}
                    ref={(slider) => (this.slider = slider)}
                  >
                    {Object.keys(lessonsPlanData).map(function (
                      lesson,
                    ) {
                      return (
                        <StripeCard
                          key={lesson.toString()}
                          index={parseInt(lesson) + 1}
                          lessons={lessons}
                          onClick={function () {
                            return onLessonSelect(
                              parseInt(lesson) + 1,
                            );
                          }}
                        />
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
            { <div className="row comments">
              <div className="col-xs-8 col-sm-offset-2">
                <p>
                  <strong>Наталя09.02 18:45</strong>
                </p>
                <p>
                  Доброго вечора. Я зареєструвалася для проходження курсу «Я починаю
                  читати». Випадкова знайшла інформацію в інтернеті і як вчитель
                  початкових класів у звичайній сільській школі зацікавилася.
                  Перейшла за посиланням, але не спішу розпочинати навчання - так
                  можна? Не зовсім зрозуміла щодо пробних уроків і подальшої плати.
                </p>

                <p>
                  <strong>Ребус-метод> Наталя09.02 19:03</strong>
                </p>
                <p>
                  Так, будь ласка, можна. Трошки докладніше можете почитати нижче у
                  відповіді Станіславові 2018.11.29 15:50 або на сторінці «Як
                  придбати» https://goo.gl/dFtd2C
                </p>
                <p>
                  Цікаво буде дізнатись, як методика буде діяти для ваших дітей.
                </p>

                <p>
                  <strong>Антоніна08.02 19:21</strong>
                </p>
                <p>Дякуємо, ми в захваті!!!!!!!!!!!!!!</p>

                <p>
                  <strong>Катерина04.02 21:17</strong>
                </p>
                <p>
                  якщо дитина в 4-р не говорить, чи варто її садити за цей курс?
                  ПОняття друку слів під диктовку вже присутнє
                </p>

                <p>
                  <strong>Ребус-метод> Катерина04.02 21:37</strong>
                </p>
                <p>
                  Краще не поспішати. Ми всі різні, і все в нас розивавається з
                  різною швидкістю. Це нормально.
                </p>
                <p>Поверніться пізніше, і задоволення від гри буде більше.</p>

                <p>
                  <strong>марія04.02 08:47</strong>
                </p>
                <p>
                  Доброго дня! Скажіть будь ласка якщо я оплачую, то в я отримаю
                  доступ до всіх уроків, але якщо мені потрібно їх в реальності
                  використовувати, то чи скидаєте все електронному варіанті?
                </p>

                <p>
                  <strong>Ребус-метод> марія04.02 08:57</strong>
                </p>
                <p>
                  Маріє, вітаю. На жаль, займатися можна лише онлайн. Виходом для
                  вас може стати гра на пристрої з мобільним інтернетом.
                </p>

                <p>
                  <strong>Ігор01.02 16:33</strong>
                </p>
                <p>
                  Доброго дня,мені щось говорили про бонусні уроки!!!Як я зрозумів
                  після 32ур.відкриються по 48ур все правильно??
                </p>
              </div>
            </div> }
          </div>
        </div>
      </div>
    );
  }
}
