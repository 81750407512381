const lessonsPlan = [
  null,
  {
    content:
      'як гратися; як читати перші слова; звуки «ка», «ки», «на», «ні», «но», «ла», «ли», «лі», «ло», «ба», «ва», «ра», «ри», «рі», «ро», «ма», «па», «та», «ти», «то», «за», «со», «я»',
    header: 'Перший урок',
    id: '1',
  },
  {
    content:
      'звуки «ви», «ві», «во», «да», «ди», «ді», «га», «го», «ке», «ку», «ле», «ля», «ні», «ня», «са», «си», «ча», «че», «бу», «ди», «мо», «о», «пі», «ре»; фотки наших учнів',
    header: 'Другий урок',
    id: '2',
  },
  {
    content:
      'читаємо слово за допомогою картинок і клацаємо на правильний варіант; якщо важко збагнути принцип',
    header: 'Третій урок',
    id: '3',
  },
  {
    content:
      'звуки «а», «би», «бі», «бо», «до», «ду», «ве», «ву», «ме», «мі», «чи», «чо», «ги», «жа», «зе», «і», «лю», «пи», «по», «сі», «те», «хи», «ця», «ша»; виріж і загадай друзям',
    header: 'Четвертий урок',
    id: '4',
  },
  {
    content:
      'відео «як починався „Ребус-метод“»; послухай слова за допомогою батьків, назви картинки і букви під ними; поділіться враженнями',
    header: 'П’ятий урок',
    id: '5',
  },
  {
    content:
      'подарункові уроки закінчилися, але можна відкрити ще кілька; прочитай ребус і вкажи правильний малюнок',
    header: 'Шостий урок',
    id: '6',
  },
  {
    content:
      'прочитай ребус і вкажи правильний малюнок; на сьомий день відпочиваємо',
    header: 'Сьомий урок',
    id: '7',
  },
  {
    content:
      'давно не підвищували складність; прочитай ребус і вкажи правильний малюнок',
    header: 'Восьмий урок',
    id: '8',
  },
  {
    content:
      'звуки «гі», «гу», «зи», «зо», «ми», «му», «пе», «пу», «ру», «ря», «се», «су», «ца», «ці», «бе», «жи», «ї», «кі», «лу», «ті», «хо», «чі», «ши»',
    header: 'Дев’ятий урок',
    id: '9',
  },
  {
    content:
      'назви картинки, а потім літери під цими картинками; прочитай ребус і вкажи правильний малюнок',
    header: 'Десятий урок',
    id: '10',
  },
  {
    content:
      'назви картинки, а потім літери під цими картинками; прочитай ребус і вкажи правильний малюнок',
    header: 'Одинадцятий урок',
    id: '11',
  },
  {
    content:
      'звуки «де», «дя», «же», «жі», «жо», «жу», «фа», «фе», «фо», «щи», «щі», «щу», «ту», «тю», «ци», «ша», «шо», «ха», «є», «у», «ні», «зу», «че», «ся»',
    header: 'Дванадцятий урок',
    id: '12',
  },
  {
    content:
      'починаємо читати ключами. Ключі-звуки «ка», «ки», «на», «ни»',
    header: 'Тринадцятий урок',
    id: '13',
  },
  {
    content:
      'прочитай за допомогою ключа і вкажи правильний малюнок. <br>Ключі-звуки «ба», «бу», «ло», «ли», «лі»',
    header: 'Чотирнадцятий урок',
    id: '14',
  },
  {
    content:
      'прочитай за допомогою ключа і вкажи правильний малюнок. <br>Ключі-звуки «ро», «ра», «ри», «па», «пі», «по»',
    header: 'П’ятнадцятий урок',
    id: '15',
  },
  {
    content:
      'прочитай за допомогою ключа і вкажи правильний малюнок. <br>Ключі-звуки «то», «та», «ти», «ва», «ви», «ві», «ве»',
    header: 'Шістнадцятий урок',
    id: '16',
  },
  {
    content:
      'прочитай за допомогою ключа і вкажи правильний малюнок. <br>Ключі-звуки «са», «си», «сі», «се», «я», «о», «а», «і», «ї», «є», «у»',
    header: 'Сімнадцятий урок',
    id: '17',
  },
  {
    content:
      'прочитай за допомогою ключа і вкажи правильний малюнок. <br>Ключі-звуки «ка», «на», «ба», «ра», «ки», «ни», «ли», «ри»',
    header: 'Вісімнадцятий урок',
    id: '18',
  },
  {
    content:
      'прочитай за допомогою ключа і вкажи правильний малюнок. <br>Ключі-звуки «у», «бу», «ку», «ду», «ву», «пу», «лу», «ко», «ло», «ро», «то», «со»',
    header: 'Дев’ятнадцятий урок',
    id: '19',
  },
  {
    content:
      'прочитай за допомогою ключа і вкажи правильний малюнок. <br>Ключі-звуки «лі», «рі», «ні», «пі», «ві», «ді», «ле», «ре», «ке», «че», «ме», «ве», «зе», «те»',
    header: 'Двадцятий урок',
    id: '20',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Ключі-звуки «о», «ко», «ло», «ро», «то», «со», «но», «мо», «го», «а», «ка», «на», «ба», «ра», «па», «ва», «та»',
    header: 'Двадцять перший урок',
    id: '21',
  },
  {
    content:
      'прочитай із ключами, вкажи правильний малюнок. Ключі-звуки «а», «ка», «на», «ба», «ра», «па», «ва», «та», «за», «ма», «ла», «га», «я», «ля», «ня», «ця», «бу», «ку», «ву», «ки», «ни»',
    header: 'Двадцять другий урок',
    id: '22',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Приголосні ключі-звуки «в», «ф»',
    header: 'Двадцять третій урок',
    id: '23',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Приголосні ключі-звуки «б», «п», «м»',
    header: 'Двадцять четвертий урок',
    id: '24',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Приголосні ключі-звуки «р», «л»',
    header: 'Двадцять п’ятий урок',
    id: '25',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Приголосні ключі-звуки «г», «к», «х»',
    header: 'Двадцять шостий урок',
    id: '26',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Приголосні ключі-звуки «д», «т», «н»',
    header: 'Двадцять сьомий урок',
    id: '27',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Приголосні ключі-звуки «з», «с», «ц»',
    header: 'Двадцять восьмий урок',
    id: '28',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Приголосні ключі-звуки «й», «ж», «ч», «ш», «щ»',
    header: 'Двадцять дев’ятий урок',
    id: '29',
  },
  {
    content:
      'прочитай за допомогою ключів. Склади з апострофом «м’я», «р’я», «п’я», «р’ї», «б’я», «в’ї», «в’я», «ш’ю»; м’який знак та буквосполучення «ьо»: «ть», «ль»',
    header: 'Тридцятий урок',
    id: '30',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>М’який знак: «ль», «нь», «льо», «цьо», «зьо»',
    header: 'Тридцять перший урок',
    id: '31',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>М’який знак: «дь», «ть», «зь», «сь», «ць»; складні слова, «молодець»',
    header: 'Тридцять другий урок',
    id: '32',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання відкритими складами.',
    header: 'Тридцять третій урок',
    id: '33',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання відкритими складами.',
    header: 'Тридцять четвертий урок',
    id: '34',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання відкритими складами.',
    header: 'Тридцять п’ятий урок',
    id: '35',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання відкритими складами.',
    header: 'Тридцять шостий урок',
    id: '36',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання відкритими складами.',
    header: 'Тридцять сьомий урок',
    id: '37',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання відкритими складами.',
    header: 'Тридцять восьмий урок',
    id: '38',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання відкритими складами.',
    header: 'Тридцять дев’ятий урок',
    id: '39',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання відкритими складами.',
    header: 'Сороковий урок',
    id: '40',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання закритими складами. Приголосні «в», «ф», «б», «п»',
    header: 'Сорок перший урок',
    id: '41',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання закритими складами. Приголосні «п», «м»',
    header: 'Сорок другий урок',
    id: '42',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання закритими складами. Приголосні «р», «л»',
    header: 'Сорок третій урок',
    id: '43',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання закритими складами. Приголосні «г», «к», «х»',
    header: 'Сорок четвертий урок',
    id: '44',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання закритими складами. Приголосні «д», «т», «н», «з», «с», «ц»',
    header: 'Сорок п’ятий урок',
    id: '45',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання закритими складами. Приголосні «ж», «ч», «ш», «щ», «й»',
    header: 'Сорок шостий урок',
    id: '46',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання закритими складами. Слова з апострофом та буквосполученням «ьо»',
    header: 'Сорок сьомий урок',
    id: '47',
  },
  {
    content:
      'прочитай за допомогою ключів і вкажи правильний малюнок. <br>Читання закритими складами. М’який знак «ль», «нь», «дь», «ть», «сь», «ць», «зь»; складні слова',
    header: 'Сорок восьмий урок',
    id: '48',
  },
];

export default lessonsPlan;
